<route>
{
  "meta": {
    "permission": [
      "settings.add_company",
      "settings.change_company"
    ]
  }
}
</route>

<template>
  <v-card class="px-1 px-lg-2 my-3">
    <ValidationObserver v-slot="{ handleSubmit }">
      <form
        enctype="multipart/form-data"
        @submit.prevent="handleSubmit(submit)"
      >
        <i-toolbar
          :title="
            edit
              ? `${$t('edit')} ${$tc('company')}`
              : `${$t('register')} ${$t('company')}`
          "
          :loading="load"
        ></i-toolbar>
        <v-card-text>
          <v-row class="align-items-center">
            <!-- Imagen -->
            <v-col cols="12" md="4" class="pt-2">
              <i-image
                :value="imageUrl"
                :file.sync="company.company_photo"
                v-model="imageUrl"
              />
            </v-col>

            <v-col cols="12" md="8">
              <v-row>
                <!-- Nombre de la empresa -->
                <v-col cols="12" md="6">
                  <ValidationProvider
                    vid="name"
                    rules="required"
                    :name="$tc('company_name')"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      tabindex="1"
                      :error-messages="errors[0]"
                      :label="$tc('company_name')"
                      v-model="company.name"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Dirección -->
                <v-col cols="12" md="6">
                  <ValidationProvider
                    vid="address"
                    rules="required"
                    :name="$tc('address', 1)"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      tabindex="2"
                      :error-messages="errors[0]"
                      :label="$tc('address', 2)"
                      v-model="company.address"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Ciudad -->
                <v-col cols="12" md="6">
                  <ValidationProvider
                    vid="city"
                    rules="required"
                    :name="$tc('city', 1)"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      tabindex="3"
                      :error-messages="errors[0]"
                      :label="$tc('city', 2)"
                      v-model="company.city"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Estado -->
                <v-col cols="12" md="6">
                  <ValidationProvider
                    vid="state"
                    rules="required"
                    :name="$tc('state', 1)"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      tabindex="4"
                      :error-messages="errors[0]"
                      :label="$tc('state', 2)"
                      v-model="company.state"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- Código postal -->
                <v-col cols="12" md="6">
                  <ValidationProvider
                    vid="zip_code"
                    :name="$tc('zip_code', 1)"
                    rules="required|numeric"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      autocomplete="off"
                      outlined
                      tabindex="5"
                      v-model="company.zip_code"
                      :label="$tc('zip_code', 2)"
                      :error-messages="errors[0]"
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <!-- País -->
                <v-col cols="12" md="6">
                  <ValidationProvider
                    vid="country"
                    :name="$t('country')"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      class="secondary--text"
                      name="country"
                      autocomplete="off"
                      item-text="name"
                      item-value="pk"
                      outlined
                      tabindex="6"
                      :error-messages="errors[0]"
                      :items="country_list"
                      :label="$t('country')"
                      v-model="company.country_id"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <!-- Moneda -->
                <v-col cols="12" md="6">
                  <ValidationProvider
                    rules="required"
                    vid="currency"
                    :name="$t('currency', 1)"
                    v-slot="{ errors }"
                  >
                    <v-autocomplete
                      class="secondary--text"
                      name="currency"
                      autocomplete="off"
                      item-value="pk"
                      outlined
                      tabindex="7"
                      :error-messages="errors[0]"
                      :item-text="getCurrencyName"
                      :items="currency_list()"
                      :label="$tc('currency', 1)"
                      v-model="company.currency_id"
                    ></v-autocomplete>
                  </ValidationProvider>
                </v-col>

                <!-- Formato de fecha -->
                <v-col cols="12" md="6">
                  <ValidationProvider
                    vid="formatDate"
                    rules="required"
                    :name="$t('format_date')"
                    v-slot="{ errors }"
                  >
                    <v-select
                      autocomplete="off"
                      name="formatDate"
                      outlined
                      tabindex="8"
                      :error-messages="errors[0]"
                      :items="['mm-dd-yyyy', 'dd-mm-yyyy', 'yyyy-mm-dd']"
                      :label="$t('format_date')"
                      v-model="company.format_date"
                    >
                    </v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>

            <!-- Usuarios -->
            <v-col class="pt-0" cols="12" md="4">
              <v-autocomplete
                v-if="isAdmin"
                class="secondary--text company-pk--select"
                name="users"
                autocomplete="off"
                chips
                item-text="username"
                item-value="id"
                outlined
                multiple
                return-object
                tabindex="9"
                :items="allUsers"
                :label="$tc('user', 1)"
                v-model="company.users"
              ></v-autocomplete>
            </v-col>

            <!-- URL -->
            <v-col cols="12" md="8" class="py-4">
              <ValidationProvider
                vid="web_url"
                :name="$t('web_url')"
                rules="url"
                v-slot="{ errors }"
              >
                <v-text-field
                  name="web_url"
                  outlined
                  tabindex="10"
                  :error-messages="errors[0]"
                  :label="$t('web_url')"
                  v-model="company.web_url"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
      </form>
    </ValidationObserver>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      load: false,
      loading: false,
      country_list: [],
      subDomains: {},
      found: {},
      edit: false,
      imageUrl: null,
      allUsers: [],
      company: {
        pk: '',
        name: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        country_id: null,
        web_url: '',
        format_date: 'mm-dd-yyyy',
        currency_id: null,
        primary_color: '#34495E',
        secondary_color: '#345D48',
        dark: false,
        company_photo: null,
        users: []
      }
    }
  },
  computed: {
    ...mapGetters({
      currency_list: 'company/getCurrencies',
      isAdmin: 'session/isAdmin'
    })
  },
  methods: {
    getCurrencyName: (val) => {
      return `${val.name} (${val.code}) `
    },
    /**
     * saveCompany
     * Método para crear un registro nuevo o editar uno existente
     * dependiendo del caso.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async saveCompany(data, id) {
      if (id) {
        this.company = await this.$api.company.edit({
          pk: id,
          form: data
        })
      } else {
        this.company = await this.$api.company.create({
          form: data
        })
      }
    },
    /**
     * setCompany
     * Método que recupera la información de la empresa en caso que se esté
     * editando y rellena el objeto company.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async setCompany() {
      let pkProp = this.codification({
        mode: 'decode',
        code: this.$route.params.pk
      })
      if (pkProp !== 'create') {
        this.edit = true
        const company = await this.$api.company.show({
          pk: pkProp
        })
        this.company = company.data
        this.imageUrl = this.company.company_photo
      }
    },
    /**
     * getUsers
     * Método que recupera todos los usuarios registrados
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async getUsers() {
      const queryUsers = await this.$api.user.list({})
      this.allUsers = queryUsers.data.results
    },
    async getCountries() {
      const country = await this.$api.country.list({})
      this.country_list = country.data
    },
    /**
     * submit
     * Método para crear una empresa nueva o editar una existente
     * dependiendo del caso.
     *
     * Angelo Osorio <danielking.321 at gmail.com>
     */
    async submit() {
      try {
        this.load = true
        let form_data = new FormData()
        for (var key in this.company) {
          if (this.company[key] == null) {
            continue
          }
          if (key == 'users') {
            form_data.append(key, JSON.stringify(this.company[key]))
          } else {
            form_data.append(key, this.company[key])
          }
          if (
            key == 'company_photo' &&
            (this.company.company_photo == null ||
              String(this.imageUrl).indexOf('http') != -1 ||
              String(this.company.company_photo).indexOf(';base64') != -1 ||
              String(this.company.company_photo).indexOf('http') != -1 ||
              String(this.imageUrl).indexOf('/sources/') != -1)
          ) {
            form_data.delete('company_photo')
          }
        }
        this.edit
          ? this.saveCompany(form_data, this.company.pk)
          : this.saveCompany(form_data)

        let message = this.edit ? this.$tc('edited', 2) : this.$tc('created', 2)
        this.$toast.success(`${this.$t('company')} ${message}`)
        this.$router.push({ name: 'company' })
      } catch (error) {
        console.error(error)
      } finally {
        this.load = false
      }
    }
  },
  mounted() {
    this.getCountries()
    this.setCompany()
    this.getUsers()
  }
}
</script>
<style lang="sass">
.v-input
  .v-text-field__details
    display: none
  &.error--text
    .v-text-field__details
      display: flex

// Select de usuarios
.company-pk--select
  &.v-select.v-select--chips:not(.v-text-field--single-line)
    &.v-text-field--enclosed
      .v-select__selections
        min-height: 54px
</style>
